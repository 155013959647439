import { useLocation } from 'react-router-dom';
import $ from 'jquery';

import Header from './header';
import Banner from './banner';
import HomeContents from './homeContents';
import Mining from './mining';
import FromFEE from './fromFee';
import Donation from './donation';
import WhitePaper from './whitePaper';


const callReferralRequest = (address) => {
    try {
        $.ajax({
            url: (process.env.REACT_APP_REF_URL + address),
            method: "GET",
            dataType:'json',
        })
        return true
    } catch (e) {
        return false
    }
}

const getWallet = (str) => {
    try {
        if (typeof str != 'string') return false;
        str = str.trim()
        str = str.toLowerCase()
        if (str.length != 40) return false;
        if (!(/^[0-9A-Fa-f]*$/.test(str))) return false;
        return callReferralRequest(str);     
    } catch (e) {
        return false
    }
} 

const Root = () => {
    const getLocation = (useLocation().pathname).split('/')

    if(getWallet(getLocation[1])) return (<><Banner/></>)
    else if(getLocation[1] === 'home') return (<><Header/><HomeContents/></>)
    else if(getLocation[1] === 'mining') return (<><Header/><Mining/></>)
    else if(getLocation[1] === 'fromfee') return (<><Header/><FromFEE/></>)
    else if(getLocation[1] === 'donation') return (<><Header/><Donation/></>)
    // else if(getLocation[1] === 'whitepaper') return (<><Header/><WhitePaper/></>)
    else if(getLocation[1] === '') return (<><Banner/></>)
    else return (<></>)
};

export default Root;