import React, { useEffect } from 'react';
import $ from 'jquery';

var homeBanner_Timer
var homeBanner_interval
var homeBanner_img_index = 1
const homeBanner_imgQty = 4
var homeBanner_RepetitionCount = 0
const homeBanner_RepetitionMaxCount = process.env.REACT_APP_HomeBanner_RepetitionCount
const bannerTextList = [
  `Bitcoin mining in 2009<br/>It was a <span class="whiteFont">target of ridicule</span>`,
  `Nevertheless,<br/> <span class="whiteFont">all the miners</span> of that time became <span class="whiteFont">rich</span>`,
  `If you had the <span class="whiteFont">chance again</span>,<br/> would you <span class="whiteFont">participate</span> in <span class="whiteFont">Bitcoin mining?</span>`,
  `<span class="whiteFont">“Bitcoin Krypton”<br/>Invites</span> you to the world of <span class="whiteFont">the rich</span>`,
]

const stopBannerInterval = () => {
  clearInterval(homeBanner_interval)
  clearTimeout(homeBanner_Timer)
}

const load = () =>{
  // set homeBanner css
  const windowHeight = window.innerHeight
  $("#homeBannerContent").css('height', windowHeight)
  $(".homeBanner").css('height', windowHeight)
  $(".homeBanner").css('width', window.outerWidth)
  $("#homeBannerSpectrum").css('background-image', "url('/img/homeBannerImg4.png')")
  $("#homeBanner").css('background-image', "url('/img/homeBannerImg1.png')")
  $("#homeBannerSpectrum .bannerTextContent").html(bannerTextList[3])
  $("#homeBanner .bannerTextContent").html(bannerTextList[0])
  $(".homeBanner .redirectButton").hide()
  
  // stop homebanner interval
  stopBannerInterval()

  // start homebanner interval
  homeBanner_interval = setInterval(() => {
    //inc Banner index
    homeBanner_img_index++

    // check homebanner state
    if(homeBanner_img_index > homeBanner_imgQty) { 
      homeBanner_RepetitionCount++ //inc repetitionCount
      homeBanner_img_index = 1 //convert img index 5 to 1
    }
    if(homeBanner_RepetitionCount >= homeBanner_RepetitionMaxCount) return stopBannerInterval() //stop homebanner repetition
    else {
      //set homebanner image
      $("#homeBannerSpectrum").css('background-image', "url('/img/homeBannerImg"+(homeBanner_img_index - 1 == 0 ? 4 : homeBanner_img_index - 1 )+".png')")
      $("#homeBanner").css('background-image', "url('/img/homeBannerImg"+homeBanner_img_index+".png')")
    }

    // set visible homebanner button
    if(homeBanner_img_index == homeBanner_imgQty) $("#homeBanner .redirectButton").show();
    else $("#homeBanner .redirectButton").hide()

    // change banner Text
    $("#homeBannerSpectrum .bannerTextContent").html(bannerTextList[(homeBanner_img_index - 2 < 0 ? 3 : homeBanner_img_index - 2 )])
    $("#homeBanner .bannerTextContent").html(bannerTextList[(homeBanner_img_index-1)])

    $("#homeBannerSpectrum").css('animation', "changePresentBanner 1s ease forwards")
    $("#homeBanner").css('animation', "changeNextBanner 1s ease forwards")
    homeBanner_Timer = setTimeout(()=>{
      $("#homeBannerSpectrum").css('animation', "")
      $("#homeBanner").css('animation', "")
    }, 1000)


}, process.env.REACT_APP_HomeBanner_RepetitionTime);
}

const moveHomepage = () => {
  stopBannerInterval()
  window.location.href = process.env.REACT_APP_URL +'home'
}

function Banner() {
  useEffect(() => {
    load()
  }, [])

  return (
      <div id="homeBannerContent">
        <div id='homeBannerSpectrum' className='homeBanner'>
          <div className='bannerText'>
            <p className='bannerTextContent'></p>
          </div>
          <div className='bannerButton'>
            <a onClick={() => moveHomepage()} className='redirectButton'></a>
          </div>
        </div>
        <div id='homeBanner' className='homeBanner'>
          <div className='bannerText'>
            <p className='bannerTextContent'></p>
          </div>
          <div className='bannerButton'>
            <a onClick={() => moveHomepage()} className='redirectButton'></a>
          </div>
        </div>
      </div>
  );
}

export default Banner;
