import React, { useEffect } from 'react';
import $ from 'jquery';

const Mining = () => {
    useEffect(() => {
        $("#fromFeeContent").css("height", window.innerHeight)
        $(".headerSubMenu_Mining").addClass("selectedSub")
        $(".headerMenu_How").addClass("selected")
    }, [])
    return (
        <>
            <div id="miningContent" className='contents'>
                <div className='contentBox'>
                    <p className='contentText'>
                        The most basic way to earn Bitcoin Krypton is to participate in Proof-of-Work (PoW)<br/><br/>
                        To participate in proof of work, visit Wallet/Miner at the top of the webpage and you will see the screen below.<br/><br/>
                    </p>
                    <div className='miningImg'>
                        <img/>
                    </div>
                    <p className='contentText'>
                        By pressing the play button, you will be participating in mining Bitcoin Krypton using your computer.<br/>
                        When you try mining, you will be connected to a mining server optimized for your computer among several mining farms, allowing multiple people to mine together.

                        As the number of miners increases, the efficiency of mining decreases.<br/><br/>

                        The earlier you participate, the higher your chances of getting Bitcoin Krypton.<br/><br/>

                        Bitcoin Krypton supports Java machine-based mining, and since it's actively restrict mining of large-capacity supernodes is much easier than mining of the original Bitcoin.Mining is possible even in mobile environments.<br/><br/>
                        Don't hesitate, get involved! 
                    </p>
                </div>
            </div>
        </>
    );
};

export default Mining;