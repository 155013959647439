import React, { useEffect } from 'react';
import $ from 'jquery';
const moment = require("moment");

const exFeeWallet = '0xf1757099c7b0dB802998cF6151adC9C76C55b4f6'
const exFeeTimeStart = 1730764800000
const exFeeTimeEnd = 1743379200000

const FromFEE = () => {
    useEffect(() => {
        $("#fromFeeContent").css("height", window.innerHeight)
        $(".headerSubMenu_FromFEE").addClass("selectedSub")
        $(".headerMenu_How").addClass("selected")
    }, [])
    return (
        <>
            <div id="fromFeeContent" className='contents'>
                <div className='contentBox'>
                    <p className='contentText'>
                        Exchange Mojaik FEE for BTCK<br/><br/>
                        1. Exchange rate : Exchange 1 BTCK for every 10,000 Mojaik FEE<br/>
                        2. Exchange period : 5/November/2024 ~ 31/January/2025<br/>
                        3. How to exchange : When you transfer Mojaik FEE tokens to the exchanger address, you can exchange them for Bitcoin Krypton in proportion to the amount transferred<br/>
                        4. Exchange address : {exFeeTimeStart <= moment.utc().valueOf() && moment.utc().valueOf() < exFeeTimeEnd ? exFeeWallet : "Open Soon"}<br/><br/><br/><br/><br/>
                        ※ Cautions<br/><br/>
                        Once the exchange period is over, it can no longer be exchanged for Mojaik FEE.
                    </p>
                </div>
            </div>
        </>
    );
};

export default FromFEE;