import $ from 'jquery';

const walletHrefIndex = 3;
const explorerHrefIndex = 2;

const clickMobileMenu = () => {
  if ($('.headerMenus').hasClass('clicked')) {
    $('.headerMenu_mobile').removeClass('clicked');
    $('.headerMenus').removeClass('clicked');
  } else {
    $('.headerMenu_mobile').addClass('clicked');
    $('.headerMenus').addClass('clicked');
  }
}

const clickMenu = (menuName, local) => {
  if ($('.headerMenu_' + menuName).hasClass('selected')) return

  if(menuName == 'OpenSource' || menuName == 'whitepaper') return

  if(menuName == 'wallet') menuName = 'wallet0'+ (Math.floor((Math.random() * walletHrefIndex) + 1));
  if(menuName == 'explorer') menuName = 'explorer0'+ (Math.floor((Math.random() * explorerHrefIndex) + 1));

  if (local) window.location.href = process.env.REACT_APP_URL + menuName
  else window.location.href = "http://" + menuName + process.env.REACT_APP_DOMAIN_URL
}

const subMenu = (menuName) => {
  if ($('.headerMenu_' + menuName).hasClass('selected')) {
    $('.headerMenu_' + menuName).removeClass('selected');
    // $('.headerMenu_' + menuName).css('color','#ffffff');
    if(window.innerWidth > 768) $('.headerMenu_' + menuName).css('color','#ffffff');
    else $('.headerMenu_' + menuName).css('color','#000000');
  } else {
    $("#header").find('.selected').removeClass('selected')
    $('.headerMenu_' + menuName).addClass('selected')
  }
}

const Header = () => {
  return (
    <>
      <div id="header">
        <img />
        <div className='headerMenu_mobile' onClick={() => clickMobileMenu()}>Menu</div>
        <div className='headerMenus'>
          <div className='headerMenu headerMenu_Home' onClick={() => clickMenu('home', true)}>Home</div>
          <div className='headerMenu headerMenu_Wallet' onClick={() => clickMenu('wallet', false)}>Wallet/ Miner</div>
          <div className='headerMenu headerMenu_Explorer' onClick={() => clickMenu('explorer', false)}>Explorer</div>
          <div className='headerMenu headerMenu_How' onClick={() => subMenu('How')}>
            How to get
            <div className='headerSubMenu'>
              <div className="headerSubMenu_Mining" onClick={() => clickMenu('mining', true)}>Mining</div>
              <div className="headerSubMenu_FromFEE" onClick={() => clickMenu('fromfee', true)}>From FEE</div>
              <div className="headerSubMenu_Donation" onClick={() => clickMenu('donation', true)}>Donation</div>
            </div>
          </div>
          <div className='headerMenu headerMenu_Whitepaper' onClick={() => clickMenu('whitepaper', true)}><del>Whitepaper</del></div>
          <div className='headerMenu headerMenu_OpenSource' onClick={() => clickMenu('OpenSource', false)} disabled><del>Open Source</del></div>
        </div>
      </div>
    </>
  );
};

export default Header;